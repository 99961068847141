import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const PromoBanner = () => {
  return (
    <Container fluid className="promo-banner">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h2 className="fw-bold">Spring into action!</h2>
          <p className="fs-5">
            Spring into action with our fast, hassle-free stump and brush removal service! Skip the long wait times at big companies and enjoy quick, efficient service that’s ready when you are. Book with us today and experience the difference!
          </p>
          <Button variant="dark" size="lg" href="tel:7165233807">
            Call for a Quote
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PromoBanner;
