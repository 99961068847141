import React, { useState } from "react";
import logo from "../img/Hooks Small Tree Stump Grinding Khawan FINAL.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-left">
        <img className="logo-footer" src={logo}></img>
        Copyright Hooks Tree & Stump Grinding Services LLC 2023
      </div>
      <div className="footer-mid">Site created by <a className="attribute" href="https://github.com/AE-Web-Solutions">AE Web Solutions</a></div>
      <div className="footer-right">
        <a href="tel:(716)-523-3807" className="footer-number">(716)-523-3807</a>
      </div>
    </div>
  );
};

export default Footer;
