import React from "react";
import FullPageSection from "../section";
import Demo from "../demo";
import logo from "../../img/Stumped.png";
import PromoBanner from "../banner";

// Inline styles for the component
const sectionStyle = {
  height: "100vh",
  backgroundImage: 'url("path/to/background-image.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex", // Set the container as a flex container
  justifyContent: "center", // Center horizontally
  alignItems: "center", // Center vertically
};

const tileStyle = {
  width: "25%",
  padding: "20px",
  boxSizing: "border-box",
};

const buttonStyle = {
  display: "block",
  marginTop: "10px",
};

const Main = () => {
  return (
    <div>
      <PromoBanner />
      {/* First full page section to contain the ~5 images and text section */}
      <FullPageSection className="landing-page">
        {/* IN CSS, this should be full page width & height with an image */}
        <div className="landing-parent">
          <div className="quote-container">
            <img className="quote" src={logo} />
            {/* <div className="landing-text">Fully Licensed and Insured</div> */}
            <div className="landing-info">
              We are your local Family Owned and Operated Small Tree and Stump
              Grinding service.
            </div>
            <div>Proudly serving Niagara and Erie county.</div>
            <div className="call-button">
              <a href="tel:7165233807" className="phone-number">
                Call for a Quote
              </a>
            </div>
          </div>
        </div>
      </FullPageSection>

      {/* Second full page page section with the demo in it */}
      <FullPageSection>
        <Demo></Demo>
      </FullPageSection>
    </div>
  );
};

export default Main;
