import React from "react";
import ComingSoon from "./coming-soon";
import VideoEmbed from "../video-embed";
import PhotoParent from "../photo-parent";

const Gallery = ({ children }) => {
  return (
    <>
      {/* <ComingSoon section="Gallery"></ComingSoon> */}
      <VideoEmbed embedId="f1LnRH2fcqU"></VideoEmbed>
      <PhotoParent></PhotoParent>
    </>
  );
};

export default Gallery;
