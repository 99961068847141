import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import MainNav from './components/navbar';
import FullPageSection from './components/section';
import MyModal from './components/modal'
import Main from './components/pages/main';
import Gallery from './components/pages/gallery';
import Footer from './components/footer';
import CallBar from './components/call-bar';

function App() {
  return (
    <div className="App">
      <CallBar></CallBar>
      <MainNav></MainNav>
      <BrowserRouter>
      <Routes>
        <Route index element={<Main></Main>} />
        <Route path="gallery"  element={<Gallery></Gallery>}></Route>
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
  
    </div>
  );
}

export default App;
